// NoteContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { axiosPrivate } from "../lib/AxiosInstance";
import { BackendRoutes } from "../constants/BackendRoute";
import { useWorkspaceContext } from "./WorkspaceContext";

const NoteContext = createContext();

export const useNoteContext = () => {
  return useContext(NoteContext);
};

export const NoteContextProvider = ({ children }) => {
  const { selectedWorkspace } = useWorkspaceContext();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpdateNoteEditorOpen, setIsUpdateNoteEditorOpen] = useState(false);

  const fetchNotes = async () => {
    try {
      if (selectedWorkspace) {
        setNotes([]);
        const response = await axiosPrivate.get(
          `${BackendRoutes.Notes}/${selectedWorkspace._id}`
        );
        setNotes([...response.data.notes]);
      }
    } catch (error) {
      setError(error.message || "Error fetching notes");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [selectedWorkspace]);

  const contextValue = {
    notes,
    loading,
    error,
    fetchNotes,
    isUpdateNoteEditorOpen,
    setIsUpdateNoteEditorOpen,
  };

  return (
    <NoteContext.Provider value={contextValue}>{children}</NoteContext.Provider>
  );
};
