// Sidebar.js

import React from "react";
import {
  FaHome,
  FaBookmark,
  FaCog,
  FaSignOutAlt,
  FaBars,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { BsMoon, BsSun } from "react-icons/bs";

const Sidebar = () => {
  const {
    isDayMode,
    toggleDayMode,
    isSidebarOpen,
    toggleSidebar,
    logOut,
    colors,
  } = useAppContext();

  return (
    <nav
      className={`${
        colors.cardColor
      } text-white h-screen w-64 transition-all duration-300 ${
        isSidebarOpen ? "ml-0" : "-ml-64"
      }`}
    >
      <div className="p-4 mt-20">
        <nav className="flex flex-col space-y-4">
          <Link
            to="/"
            className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            <FaHome />
            <span>Home</span>
          </Link>
          <Link
            to="/create-workspace"
            className={`flex items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            <FaBookmark />
            <span>Create Workspace</span>
          </Link>
          <div
            onClick={toggleDayMode}
            className={`flex cursor-pointer items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            {isDayMode ? <BsMoon /> : <BsSun />}
            <span>{isDayMode ? "Dark" : "Light"}</span>
          </div>
          <div
            onClick={logOut}
            className={`flex cursor-pointer items-center space-x-2 text-white hover:bg-blue-500 hover:text-white px-4 py-2 rounded-full ${
              isDayMode ? "bg-gray-700" : "bg-gray-500"
            }`}
          >
            <FaSignOutAlt />
            <span>Logout</span>
          </div>
        </nav>
      </div>
    </nav>
  );
};

export default Sidebar;
