import React, { useState, useEffect } from "react";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { useAppContext } from "../../context/AppContext";
import { FaChevronDown, FaEdit, FaSave } from "react-icons/fa";

const SelectWorkspace = ({ setIsEditorOpen }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const { workspaces, selectedWorkspace, setSelectedWorkspace } =
    useWorkspaceContext();
  const { colors } = useAppContext();

  useEffect(() => {
    // Select the first workspace by default if the array has elements
    if (workspaces.length > 0 && selectedWorkspace === null) {
      setSelectedWorkspace(workspaces[0]);
    }
  }, [workspaces]);

  useEffect(() => {
    if (selectedWorkspace && selectedWorkspace["encKey"]) {
      setEditedValue(selectedWorkspace["encKey"]);
    }
  }, [selectedWorkspace]);

  const handleDropdownChange = (event) => {
    const selectedId = event.target.value;
    const selected = workspaces.find(
      (workspace) => workspace._id === selectedId
    );
    setSelectedWorkspace(selected);
    localStorage.setItem("currentWorkspace", selected._id);
    setIsEditable(false);
    setEditedValue("");
  };

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
    if (!isEditable) {
      setEditedValue("");
    } else {
      if (selectedWorkspace) {
        selectedWorkspace["encKey"] = editedValue;
        setSelectedWorkspace({ ...selectedWorkspace });
      }
    }
  };

  const handleInputChange = (event) => {
    setEditedValue(event.target.value);
  };

  return (
    <div
      className={`max-w-xl mx-auto mb-8  overflow-hidden rounded-md shadow-lg ${colors.cardColor}`}
    >
      <div
        className={`p-4 pb-1 grid grid-cols-1 md:grid-cols-2 gap-4 ${
          selectedWorkspace && selectedWorkspace.clientEncryptionKey
            ? "md:grid-cols-2"
            : "md:grid-cols-1"
        }`}
      >
        <div className="">
          <label
            htmlFor="workspaceDropdown"
            className={`block mb-1 text-sm font-semibold ${colors.text}`}
          >
            Select Workspace
          </label>
          <div className="relative">
            <select
              id="workspaceDropdown"
              value={selectedWorkspace ? selectedWorkspace._id : ""}
              onChange={handleDropdownChange}
              className={`flex-shrink appearance-none w-full px-4 py-2.5 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline ${colors.inputBackground} ${colors.border} ${colors.text}`}
            >
              {workspaces.map((workspace) => (
                <option key={workspace._id} value={workspace._id}>
                  {workspace.name}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              {/* You can customize the dropdown icon */}
              <FaChevronDown className="text-blue-500" />
            </div>
          </div>
        </div>

        {selectedWorkspace && selectedWorkspace.clientEncryptionKey && (
          <div className="flex items-center">
            <div className="w-full">
              <label
                htmlFor="editInput"
                className={`block mb-1 text-sm font-semibold ${colors.text}`}
              >
                Set Encryption Key
              </label>
              <div className={`flex row items-center`}>
                <div style={{ flex: 1 }}>
                  <input
                    name="p1"
                    id="txtPassword"
                    type="password"
                    autoComplete="off"
                    value={editedValue}
                    onChange={handleInputChange}
                    disabled={!isEditable}
                    className={`border px-3 py-2 w-full rounded-md ${
                      isEditable ? "border-blue-300" : "border-none"
                    } focus:outline-none focus:border-blue-300 ${
                      colors.inputBackground
                    } ${colors.border} ${colors.text}`}
                  />
                </div>

                <button
                  onClick={handleEditToggle}
                  className={`bg-blue-500 hover:bg-blue-600 text-white px-4 ml-2 py-2 rounded-md focus:outline-none`}
                >
                  {isEditable ? <FaSave size={24} /> : <FaEdit size={24} />}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-4 pb-4">
        <div
          onClick={() => {
            setIsEditorOpen(true);
          }}
          id="descriptionText"
          className={`p-2 mt-2 cursor-pointer rounded-md ${colors.inputBackground}`}
        >
          <p className={`font-semibold text-gray-500 `}>Write note...</p>
        </div>
      </div>
    </div>
  );
};

export default SelectWorkspace;
