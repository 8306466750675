import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { useAppContext } from "../../context/AppContext";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { FaChevronLeft, FaChevronRight, FaEdit, FaTrash } from "react-icons/fa";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { BackendRoutes } from "../../constants/BackendRoute";
import { toast } from "react-toastify";
import { useNoteContext } from "../../context/NoteContext";
import CryptoJS from "crypto-js";

const UpdateEditor = ({ isEditorOpen, setIsEditorOpen, note }) => {
  const [noteContent, setNoteContent] = useState("");
  const { colors, isDayMode } = useAppContext();
  const { selectedWorkspace } = useWorkspaceContext();
  const { fetchNotes } = useNoteContext();
  const [editingEnable, setEditingEnable] = useState(false);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const [noteVersions, setNoteVersions] = useState([]);

  useEffect(() => {
    if (isEditorOpen && note) {
      const { versions, ...latestNote } = note;
      let tempNoteVersions = [...note.versions, latestNote];
      setNoteVersions(tempNoteVersions);
      // Set the current version index to the latest version
      setCurrentVersionIndex(tempNoteVersions.length - 1);
      setNoteContent(
        handleNoteDecryption(
          tempNoteVersions[tempNoteVersions.length - 1].content
        )
      );
    }
  }, [isEditorOpen, note]);

  const handleContentChange = (content) => {
    setNoteContent(content);
  };

  const handleNoteDecryption = (noteText) => {
    if (selectedWorkspace && !selectedWorkspace.clientEncryptionKey) {
      return noteText;
    }
    let decryptedText = "";
    try {
      if (
        selectedWorkspace &&
        selectedWorkspace["encKey"] &&
        selectedWorkspace["encKey"].length >= 6
      ) {
        const bytes = CryptoJS.AES.decrypt(
          noteText,
          selectedWorkspace["encKey"]
        );
        decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) { }

    return decryptedText;
  };

  const switchVersion = (direction) => {
    if (note && note.versions) {
      const newIndex =
        direction === "prev"
          ? currentVersionIndex + 1
          : currentVersionIndex - 1;

      if (newIndex >= 0 && newIndex < noteVersions.length) {
        setCurrentVersionIndex(newIndex);
        setNoteContent(handleNoteDecryption(noteVersions[newIndex].content));
      }
    }
  };

  const saveNote = async () => {
    setEditingEnable(false);
    try {
      if (noteContent.trim().length === 0) {
        return toast.info("Please write a note.");
      }
      if (noteContent === note?.content) {
        return toast.info("Nothing to save", { toastId: "same" });
      }

      let noteString = noteContent;
      if (selectedWorkspace.clientEncryptionKey) {
        if (
          selectedWorkspace["encKey"] &&
          selectedWorkspace["encKey"].trim().length >= 6
        ) {
          // encrypt
          noteString = CryptoJS.AES.encrypt(
            noteContent,
            selectedWorkspace["encKey"]
          ).toString();

          if (noteContent === handleNoteDecryption(note?.content)) {
            return toast.info("Nothing to save", { toastId: "same" });
          }
          //console.log(ciphertext, "encrpyted");
        } else {
          return toast.info(
            "Valid Encryption key is required for this Workspace"
          );
        }
      }

      await axiosPrivate.post(BackendRoutes.UpdateNote, {
        noteId: note?._id,
        content: noteString,
        workspaceId: selectedWorkspace._id,
      });
      fetchNotes();
      setNoteContent("");
      setIsEditorOpen(false);
      toast.success("Success");
    } catch (error) {
      toast.info("Failed to save the note.");
    }
  };

  const modalStyles = {
    modal: {
      background: !isDayMode ? "#fff" : "#1f2937",
      marginTop: window.innerWidth <= 640 ? 8 : 100,
      maxWidth: 600,
      width: "100%",
      marginLeft: 0,
      minHeight: window.innerWidth <= 640 ? "calc(100vh - 30px)" : 0,
    },
    closeButton: {
      color: !isDayMode ? "#000" : "#fff",
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
    },
    modalCard: {
      background: !isDayMode ? "#fff" : "#1f2937",
      color: !isDayMode ? "#000" : "#fff",
    },
  };

  const handleDelete = async () => {
    if (!editingEnable) {
      return toast.info("Please open in Edit Mode to Delete");
    }

    try {
      await axiosPrivate.post(BackendRoutes.DeleteNote, {
        noteId: note?._id,
      });
      fetchNotes();
      setNoteContent("");
      setEditingEnable(false);
      setIsEditorOpen(false);
      toast.success("Note deleted");
    } catch (error) {
      toast.info("Failed to delete note");
    }
  };

  return (
    <Modal
      open={isEditorOpen}
      onClose={() => {
        setIsEditorOpen(false);
      }}
      showCloseIcon={false}
      styles={modalStyles}
      classNames={{
        modal: "w-full sm:w-auto", // Set full width on small screens
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <label
          htmlFor="noteContent"
          className={`block text-m font-semibold ${colors.text}`}
        >
          {selectedWorkspace && "WORKSPACE : " + selectedWorkspace.name}
        </label>
        <div
          className="cursor-pointer flex justify-content-center items-center gap-4"
          style={modalStyles.closeButton}
        >
          <FaTrash onClick={handleDelete} color={"red"} />

          {editingEnable && <p className={colors.text}>Editing...</p>}
          <FaEdit
            onClick={() => {
              setEditingEnable(!editingEnable);
            }}
            className="mr-2"
            color={
              !editingEnable ? colors.btnColorPrimary : colors.btnColorSecondary
            }
            size={24}
          />
        </div>
      </div>
      <ReactQuill
        readOnly={!editingEnable}
        theme={"snow"}
        value={noteContent}
        placeholder="Write your note..."
        onChange={handleContentChange}
        style={{ color: isDayMode ? "#fff" : "#000" }}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            ["clean"],
          ],
        }}
      />
      <div className="flex justify-between mt-4 select-none">
        <div className="flex gap-2 items-center">
          <div className={`${colors.text} min-w-[80px]`}>
            {currentVersionIndex === noteVersions.length - 1
              ? "Latest"
              : "Version: " + currentVersionIndex}
          </div>
          <FaChevronLeft
            size={20}
            color={colors.text}
            onClick={() => switchVersion("prev")}
            style={{
              cursor: "pointer",
              color: colors.btnColorPrimary,
              margin: 5,
            }}
          />
          <FaChevronRight
            size={20}
            color={colors.text}
            onClick={() => switchVersion("next")}
            style={{
              cursor: "pointer",
              color: colors.btnColorPrimary,
              margin: 5,
            }}
          />
        </div>
        <div>
          <button
            className={`text-white bg-red-500 px-2 sm:px-4 py-1 sm:py-2 rounded-md mr-4`}
            onClick={() => {
              setIsEditorOpen(false);
              setEditingEnable(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`text-white bg-blue-500 px-2 sm:px-4 py-1 sm:py-2 rounded-md`}
            onClick={saveNote}
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateEditor;
