import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { useAppContext } from "../../context/AppContext";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { FaCheck, FaSave, FaTimes } from "react-icons/fa";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { BackendRoutes } from "../../constants/BackendRoute";
import { toast } from "react-toastify";
import { useNoteContext } from "../../context/NoteContext";
import CryptoJS from "crypto-js";

const NoteEditor = ({ isEditorOpen, setIsEditorOpen }) => {
  const [noteContent, setNoteContent] = useState("");
  const { colors, isDayMode } = useAppContext();
  const { selectedWorkspace } = useWorkspaceContext();
  const { fetchNotes } = useNoteContext();

  const handleContentChange = (content) => {
    setNoteContent(content);
  };

  const saveNote = async () => {
    try {
      if (noteContent.trim().length === 0) {
        return toast.info("Please write note..");
      }

      let noteString = noteContent;
      if (selectedWorkspace.clientEncryptionKey) {
        if (
          selectedWorkspace["encKey"] &&
          selectedWorkspace["encKey"].trim().length >= 6
        ) {
          // encrypt
          noteString = CryptoJS.AES.encrypt(
            noteContent,
            selectedWorkspace["encKey"]
          ).toString();
          //console.log(ciphertext, "encrpyted");
        } else {
          return toast.info(
            "Valid Encryption key is required for this Workspace"
          );
        }
      }

      await axiosPrivate.post(BackendRoutes.CreateNote, {
        content: noteString,
        workspaceId: selectedWorkspace._id,
      });
      fetchNotes();
      setNoteContent("");
      setIsEditorOpen(false);
      toast.success("Success");
    } catch (error) {
      // Handle errors, show toast, etc.
      toast.info("Failed to save note.");
      // You can add a toast library or your own error handling here
    }
  };

  const modalStyles = {
    modal: {
      background: !isDayMode ? "#fff" : "#1f2937",
      marginTop: 100,
      maxWidth: 600,
      width: "100%",
      marginLeft: 0,
    },
    closeButton: {
      color: !isDayMode ? "#000" : "#fff",
      position: "absolute",
      top: 10,
      right: 10,
      cursor: "pointer",
    },
    modalCard: {
      background: !isDayMode ? "#fff" : "#1f2937",
      color: !isDayMode ? "#000" : "#fff",
    },
  };

  return (
    <Modal
      open={isEditorOpen}
      onClose={() => {
        setIsEditorOpen(false);
      }}
      showCloseIcon={false}
      styles={modalStyles}
    >
      <div className="flex justify-between items-center mb-4">
        <label
          htmlFor="noteContent"
          className={`block text-m font-semibold ${colors.text}`}
        >
          {selectedWorkspace && "Workspace: " + selectedWorkspace.name}
        </label>
        <div
          className="cursor-pointer flex justify-content-center items-center gap-4"
          onClick={() => {
            setIsEditorOpen(false);
          }}
          style={modalStyles.closeButton}
        >
          <p className={`${colors.text} mx-4`}>Editing...</p>
          {/* <FaTimes size={25} /> */}
          {/* <FaCheck className="mr-2">Save</FaCheck> */}
        </div>
      </div>
      <ReactQuill
        theme="snow"
        value={noteContent}
        onChange={handleContentChange}
        style={{ color: isDayMode ? "#fff" : "#000" }}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            ["clean"],
          ],
        }}
      />
      <div className="flex justify-end mt-4">
        <button
          className={`text-white bg-red-500 px-4 py-2 rounded-md mr-4`}
          onClick={() => {
            setIsEditorOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          className={`text-white bg-blue-500 px-4 py-2 rounded-md`}
          onClick={saveNote}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default NoteEditor;
