// Dashboard.js

import React, { useEffect, useState } from "react";
import SelectWorkspace from "../../components/SelectWorkspace";
import NoteEditor from "../../components/NoteEditor";
import { useNoteContext } from "../../context/NoteContext";
import NoteCard from "../../components/Notes";
import { AppConfig } from "../../Config";

const Dashboard = () => {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const { notes } = useNoteContext();
  useEffect(() => {
    document.title = `Home | ${AppConfig.APP_NAME}`;
  }, []);
  return (
    <div className="mt-4">
      <div className="mx-2">
        <SelectWorkspace setIsEditorOpen={setIsEditorOpen} />
      </div>

      <NoteCard notes={notes} />

      <NoteEditor
        isEditorOpen={isEditorOpen}
        setIsEditorOpen={setIsEditorOpen}
      />
    </div>
  );
};

export default Dashboard;
