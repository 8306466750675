import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import DashboardLayout from "../layouts/DashboardLayout";
import { AppContextProvider } from "../context/AppContext";
import CreateWorkspace from "../pages/CreateWorkspace";
import Login from "../pages/Login";
import Register from "../pages/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WorkspaceContextProvider } from "../context/WorkspaceContext";

function AppNavigations() {
  return (
    <AppContextProvider>
      <ToastContainer />
      <Router>
        <Routes>
          {/* <Route path="/login" component={} /> */}
          {/* <Route path="/register" component={Register} /> */}
          <Route path="/" element={<DashboardLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="/create-workspace" element={<CreateWorkspace />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default AppNavigations;
