import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import UpdateEditor from "../UpdateEditor";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const NoteCard = ({ notes }) => {
  const { colors } = useAppContext();
  const [selectedNote, setSelectedNote] = useState(null);
  const [openUpateEditor, setOpenUpateEditor] = useState(false);
  const { selectedWorkspace } = useWorkspaceContext();

  useEffect(() => {
    if (selectedNote) {
      console.log(selectedNote);
      setOpenUpateEditor(true);
    }
  }, [selectedNote]);

  const handleUpdateNote = (note) => {
    if (
      selectedWorkspace &&
      selectedWorkspace.clientEncryptionKey &&
      selectedWorkspace["encKey"] === undefined
    ) {
      return toast.info("Provide Encryption Key first");
    }
    if (note) {
      setSelectedNote({ ...note });
    }
  };

  const handleNoteDecryption = (noteText) => {
    if (selectedWorkspace && !selectedWorkspace.clientEncryptionKey) {
      return noteText;
    }
    let decryptedText = "";
    try {
      if (
        selectedWorkspace &&
        selectedWorkspace["encKey"] &&
        selectedWorkspace["encKey"].length >= 6
      ) {
        const bytes = CryptoJS.AES.decrypt(
          noteText,
          selectedWorkspace["encKey"]
        );
        decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      }
    } catch (error) { }

    return decryptedText;
  };

  return (
    <div className="w-full flex justify-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 w-full mx-2">
        {notes.map((note) => (
          <div
            key={note._id}
            onClick={() => {
              handleUpdateNote(note);
            }}
            className={`w-full cursor-pointer  ${colors.cardColor} rounded overflow-hidden shadow-lg mb-4`}
            style={{ height: "300px" }} // Adjust the height value as needed
          >
            <div className="px-6 py-4  flex flex-col justify-between ">
              <div className="font-bold   text-xl ">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      handleNoteDecryption(note.content) === ""
                        ? ""
                        : handleNoteDecryption(note.content),
                  }}
                  style={{ height: 250, overflow: "hidden", marginBottom: 8 }}
                ></div>
              </div>
              <div
                className={`text-gray-700 text-base  ${colors.secondaryText}`}
              >
                {new Date(note.createdAt).toLocaleString()}
              </div>
            </div>
          </div>
        ))}
      </div>

      <UpdateEditor
        noteId={selectedNote?._id}
        note={selectedNote}
        isEditorOpen={openUpateEditor}
        setIsEditorOpen={setOpenUpateEditor}
      />
    </div>
  );
};

export default NoteCard;
