// WorkspaceContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { axiosPrivate } from "../lib/AxiosInstance";
import { BackendRoutes } from "../constants/BackendRoute";
import { useAppContext } from "./AppContext";

const WorkspaceContext = createContext();

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext);
};

export const WorkspaceContextProvider = ({ children }) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const { user } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchWorkspaces = async () => {
    try {
      const response = await axiosPrivate.get(BackendRoutes.Workspaces);
      setWorkspaces([...response.data]);
      if (response.data) {
        const savedWorkspaceId = localStorage.getItem("currentWorkspace");
        const selected = response.data.find(
          (workspace) => workspace._id === savedWorkspaceId
        );

        if (selected) {
          setSelectedWorkspace(selected);
        }
      }
    } catch (error) {
      setError(error.message || "Error fetching workspaces");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user) {
      fetchWorkspaces();
    }
  }, [user]);

  const contextValue = {
    workspaces,
    loading,
    error,
    fetchWorkspaces,
    selectedWorkspace,
    setSelectedWorkspace,
  };

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {children}
    </WorkspaceContext.Provider>
  );
};
