import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BackendRoutes } from "../../constants/BackendRoute";
import { axiosInstance } from "../../lib/AxiosInstance";
import { toast } from "react-toastify";
import { AppConfig } from "../../Config";
import { FaBookOpen, FaLock } from "react-icons/fa";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { colors, loginUser, user } = useAppContext();
  const navigation = useNavigate();

  useEffect(() => {
    document.title = `Log in to ${AppConfig.APP_NAME}`;
  }, []);

  useEffect(() => {
    if (user) {
      navigation("/", { replace: true });
    }
  }, [user, navigation]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(BackendRoutes.Login, {
        username,
        password,
      });

      const { status, msg, payload } = response.data;

      if (status === 200) {
        // Login successful
        console.log(msg);
        console.log(payload);

        // Store user data in context
        loginUser(payload);

        // Redirect to home page
        navigation("/", { replace: true });
      } else {
        // Login failed
        console.error(msg);
        toast.info(msg);
        // Handle form validation errors
        if (payload) {
          setFormErrors(payload);
        }
      }
    } catch (error) {
      toast.info(error.response.data.msg, { toastId: 1 });
    }
  };

  return (
    <div className={`flex flex-col md:flex-row h-screen ${colors.background}`}>
      {/* First Column (Promotional Text) - Hidden on small devices */}
      <div
        className={`md:w-1/2 hidden md:block p-8 md:p-16 bg-gray-800 text-white ${colors.cardColor}`}
      >
        <h1
          className="text-5xl font-semibold mb-10"
          style={{ color: colors.btnColorPrimary }}
        >
          Welcome to {AppConfig.APP_NAME}
        </h1>
        <h2
          className="text-4xl  font-semibold mb-6"
          style={{ color: colors.btnColorSecondary }}
        >
          Elevate Your Note-Taking Experience
        </h2>

        <div className="flex items-center mb-4">
          <FaLock size={100} color={colors.btnColorPrimary} className="mr-6" />{" "}
          {/* Lock Icon */}
          <p className={`text-lg ${colors.text}`}>
            Sign in for a powerful note-taking app that goes beyond the
            ordinary. Unlock the potential of multiple workspaces, allowing you
            to organize your thoughts, projects, and ideas seamlessly.
          </p>
        </div>
        <div className="flex items-center mb-4">
          <FaBookOpen
            size={100}
            color={colors.btnColorPrimary}
            className="mr-6"
          />{" "}
          {/* Book Open Icon */}
          <p className={`text-lg ${colors.text}`}>
            Experience the added layer of security with client-side encryption.
            Your notes are your private space, and our app ensures they stay
            that way. Encrypt your workspace with your own password, putting you
            in control of your data's confidentiality.
          </p>
        </div>
      </div>

      {/* Second Column (Login Card) */}
      <div className={`md:w-1/2 p-8 md:p-16 ${colors.background}`}>
        <h2 className={`text-3xl font-semibold mt-10 mb-6 ${colors.text}`}>
          Log in to {AppConfig.APP_NAME}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Email or Phone
            </label>
            <input
              type="text"
              id="username"
              placeholder="Email or Phone"
              className={`mt-1 p-2 w-full border rounded-md ${
                colors.inputBackground
              } border-gray-300 ${formErrors.username ? "border-red-500" : ""}`}
              onChange={(e) => setUsername(e.target.value)}
            />
            {formErrors.username && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.username.msg}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className={`mt-1 p-2 w-full border rounded-md border-gray-300 ${
                colors.inputBackground
              } ${formErrors.password ? "border-red-500" : ""}`}
              onChange={(e) => setPassword(e.target.value)}
            />
            {formErrors.password && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.password.msg}
              </p>
            )}
          </div>

          <button
            type="submit"
            className={`py-2 px-4 text-white rounded-md focus:outline-none focus:ring focus:border-blue-300 ${colors.button} bg-blue-500 hover:bg-blue-600`}
          >
            Log In
          </button>
        </form>

        <div className="mt-4 text-sm text-gray-700">
          <Link to="/forgot-password" className="text-blue-500">
            Forgot Password?
          </Link>
        </div>

        <hr className={`my-4 border-t ${colors.border}`} />

        <div className="text-center text-sm text-gray-700">
          Don't have an account?{" "}
          <Link to="/register" className="text-blue-500">
            Sign Up for {AppConfig.APP_NAME}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
