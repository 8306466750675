import React from "react";
import Header from "../components/Header";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useAppContext } from "../context/AppContext";
import { WorkspaceContextProvider } from "../context/WorkspaceContext";
import { NoteContextProvider } from "../context/NoteContext";
function DashboardLayout() {
  const { isDayMode, toggleDayMode, isSidebarOpen, toggleSidebar, user } =
    useAppContext();
  const navigation = useNavigate();
  if (!user) {
    navigation("/login");
  }
  return user ? (
    <WorkspaceContextProvider>
      <NoteContextProvider>
        <Header />
        <div className="flex h-screen bg-gray-100">
          <Sidebar />
          <div className="flex-1 flex flex-col overflow-hidden">
            <main
              className={`flex-1 overflow-x-hidden overflow-y-auto ${
                isDayMode ? "bg-gray-900 text-white" : "bg-gray-200"
              } `}
              style={{ marginTop: 70 }}
            >
              <Outlet />
            </main>
          </div>
        </div>
      </NoteContextProvider>
    </WorkspaceContextProvider>
  ) : (
    <></>
  );
}

export default DashboardLayout;
