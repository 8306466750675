export const BackendRoutes = {
  Register: "/auth/register",
  Login: "/auth/login",
  CreateWorkspace: "/workspace/create",
  Workspaces: "/workspaces",
  CreateNote: "/note/create",
  Notes: "/notes",
  UpdateNote: "/note/update",
  DeleteNote: "/note/delete",
};
