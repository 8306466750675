import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { axiosInstance, axiosPrivate } from "../../lib/AxiosInstance"; // Import your axios instance
import { BackendRoutes } from "../../constants/BackendRoute";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { AppConfig } from "../../Config";

const WorkspaceForm = () => {
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceDescription, setWorkspaceDescription] = useState("");
  const [encryptWithOwnKey, setEncryptWithOwnKey] = useState(false);
  const { isDayMode, toggleDayMode, isSidebarOpen, toggleSidebar, colors } =
    useAppContext();
  const { fetchWorkspaces } = useWorkspaceContext();

  useEffect(() => {
    document.title = `Create Workspace | ${AppConfig.APP_NAME}`;
  }, []);

  const navigation = useNavigate();

  const getTextColor = () => {
    return isDayMode ? "text-white" : "text-black";
  };

  const getBorderColor = () => {
    return isDayMode ? "border-gray-600" : "border-gray-300";
  };

  const getCheckboxColor = () => {
    return isDayMode ? "text-gray-300" : "text-gray-600";
  };

  const getButtonColor = () => {
    return isDayMode
      ? "bg-blue-700 hover:bg-blue-800"
      : "bg-blue-500 hover:bg-blue-600";
  };

  const handleSubmit = async () => {
    const formData = {
      name: workspaceName,
      description: workspaceDescription,
      clientEncryptionKey: encryptWithOwnKey,
    };

    try {
      const response = await axiosPrivate.post(
        BackendRoutes.CreateWorkspace,
        formData
      );

      if (response.status === 200) {
        // Successful response
        console.log("Workspace created successfully");
        // Redirect to the / page
        // You may want to use a routing library like react-router-dom for navigation
        // Example using window.location
        fetchWorkspaces();
        navigation("/", { replace: true });
      } else {
        // Error response
        console.error("API Error:", response.data);

        // Handle errors and display them to the user
        // You may want to use state to manage error messages in your component
      }
    } catch (error) {
      if (error.response.data?.errors?.length > 0) {
        toast.info(error.response.data?.errors[0].msg);
      } else {
        toast.info("Something went wrong");
      }
    }
  };

  return (
    <div className={`flex items-center justify-center mt-10`}>
      <div
        className={`max-w-md w-full ${
          isDayMode ? "bg-gray-800" : "bg-white"
        } p-8 rounded-lg shadow-md`}
      >
        <h2 className={`text-2xl font-semibold mb-6 ${getTextColor()}`}>
          Create Workspace
        </h2>

        <div className="mb-4">
          <label
            htmlFor="workspaceName"
            className={`block text-sm font-medium ${colors.text}`}
          >
            Workspace Name
          </label>
          <input
            type="text"
            id="workspaceName"
            className={`mt-1 p-2 w-full border ${
              colors.inputBackground
            } rounded-md ${getBorderColor()}`}
            onChange={(e) => setWorkspaceName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="workspaceDescription"
            className={`block text-sm font-medium ${getTextColor()}`}
          >
            Workspace Description
          </label>
          <textarea
            id="workspaceDescription"
            rows="4"
            className={`mt-1 ${
              colors.inputBackground
            } p-2 w-full border rounded-md ${getBorderColor()}`}
            onChange={(e) => setWorkspaceDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              className={getCheckboxColor()}
              checked={encryptWithOwnKey}
              onChange={() => setEncryptWithOwnKey(!encryptWithOwnKey)}
            />
            <span className={`text-sm font-medium ${getCheckboxColor()}`}>
              Encrypt with own key
            </span>
          </label>
        </div>

        <button
          className={`py-2 px-4 text-white rounded-md focus:outline-none focus:ring focus:border-blue-300 ${getButtonColor()}`}
          onClick={handleSubmit}
        >
          Create Workspace
        </button>
      </div>
    </div>
  );
};

export default WorkspaceForm;
